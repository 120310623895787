import React, {useState} from "react"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../components/layout"
import ApplicationForm from "../components/application/application_form"
import ApplicationModal from "../components/application/application_modal"
import StepSwitcher from "../components/application/step_switcher"
import BannerBars from '../components/icons/banner_bars'
import { GoogleReCaptchaProvider} from 'react-google-recaptcha-v3'
import "../components/application/application.css"

const ApplicationPage = (props) => {
  const {strapiApplicationPageFrench: data} = useStaticQuery(graphql`
    query {
      strapiApplicationPageFrench {
        text
        title
        applicationQuestion
        applicationHeaderText
        confirmationModalTitle
        confirmationModalText
        termsOfService
        bannerImage{
          localFile{
            publicURL
          }
          
        }
        applicationHowItWorks{
          stepOneTitle
          stepOneText
          stepOneIcon{
            localFile{
              publicURL
            }
          }
          stepTwoTitle
          stepTwoText
          stepTwoIcon{
            localFile{
              publicURL
            }
          }
          stepThreeTitle
          stepThreeText
          stepThreeIcon{
            localFile{
              publicURL
            }
          }
          stepFourTitle
          stepFourText
          stepFourIcon{
            localFile{
              publicURL
            }
          }
        }
      }
    }
  `)
  const [modalActive, setModalActive] = useState(false)
  const [currentModal, setCurrentModal] = useState("terms")
  const [loading, setLoading] = useState(false)
  const clearModalActive = ()=>{
    setModalActive(false)
  }
  const setModal = (active, modal)=>{
    setModalActive(active)
    setCurrentModal(modal)
  }
  return(
    <Layout language="french" path={props.path}>
      <ApplicationModal 
        clearModalActive={clearModalActive} 
        terms={data.termsOfService} 
        modalActive={modalActive}
        currentModal={currentModal} 
        confirmationTitle={data.confirmationModalTitle}
        confirmationText={data.confirmationModalText}
        language="french"
      />
      <SEO language="french" title="Application" />
      <img className="application-mobile-banner" src={data.bannerImage.localFile.publicURL}/>
      <div style={{marginBottom:"40px"}} className="application-top-section">
        <div style={{paddingTop:"20px"}}>
          <h1 className="page-title left">{data.title}</h1>
          <p style={{fontFamily:"Rift"}}>{data.text}</p>
        </div>
        <div style={{position:"relative", backgroundImage:`url("${data.bannerImage.localFile.publicURL}")`}}>
          <BannerBars className="banner-bars" />
        </div>
      </div>
      <div className="application-section">
        <div className="application-how-it-works">
          <h2 className="section-title">Comment ça fonctionne</h2>
          <div>
            <div className="how-it-works-step-hor">
              <div>
                <div role="text" aria-label="Step One" style={{backgroundColor:"#2fa843"}}>1</div>
                <img alt="" aria-hidden="true" src={data.applicationHowItWorks.stepOneIcon.localFile.publicURL} />
                <p>{data.applicationHowItWorks.stepOneTitle}</p>
              </div>
              <p>{data.applicationHowItWorks.stepOneText}</p>
            </div>
            <div className="how-it-works-step-hor">
              <div>
                <div role="text" aria-label="Step Two" style={{backgroundColor:"#dca71c"}}>2</div>
                <img alt="" aria-hidden="true" src={data.applicationHowItWorks.stepTwoIcon.localFile.publicURL} />
                <p>{data.applicationHowItWorks.stepTwoTitle}</p>
              </div>
              <p>{data.applicationHowItWorks.stepTwoText}</p>
            </div>
            <div className="how-it-works-step-hor">
              <div>
                <div  role="text" aria-label="Step Three" style={{backgroundColor:"#2fa843"}}>3</div>
                <img alt="" aria-hidden="true" src={data.applicationHowItWorks.stepThreeIcon.localFile.publicURL} />
                <p>{data.applicationHowItWorks.stepThreeTitle}</p>
              </div>
              <p>{data.applicationHowItWorks.stepThreeText}</p>
            </div>
            <div className="how-it-works-step-hor">
              <div>
                <div role="text" aria-label="Step Four" style={{backgroundColor:"#dca71c"}}>4</div>
                <img alt="" aria-hidden="true" src={data.applicationHowItWorks.stepFourIcon.localFile.publicURL} />
                <p>{data.applicationHowItWorks.stepFourTitle}</p>
              </div>
              <p>{data.applicationHowItWorks.stepFourText}</p>
            </div>
          </div>
        </div>
        <div className="application-how-it-works-mobile">
          <h2 className="section-title">Comment ça fonctionne</h2>
          <StepSwitcher data={data} />
        </div>
        <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}>
          <ApplicationForm
            language="french"
            modalActive={modalActive}
            loading={loading}
            setLoading={setLoading}
            setModal={setModal}
            data={data} 
           />
        </GoogleReCaptchaProvider>
      </div>

    </Layout>
  )
}

export default ApplicationPage